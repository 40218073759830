import React, { useState, useEffect, useRef } from "react";
import InstallAppHeader from "./InstallAppHeader";
import Carousel from "./Carousel";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import OTPVerify from "./OTPVerify";
import { AUTH_BASE_URL } from "../../utils/ApiConstants";
import BfCoins from "../../assests/icons/BFcoins.png";
import {
  sendEventsToAnalyticsSystems,
  trackPageview,
} from "../../utils/analytics";
import LungHealthTestNav from "../LungHealthTest/LungHeathTestNav";
import loginbanner from "../../assests/images/Profiledetail.png";
import info from "../../assests/icons/info.png";
import closeicon from "../../assests/icons/closeicon.png"

const consentData = {
  en: [
    `Cipla Digital Health Limited (hereinafter referred
     to as "we" or "our" or "us") respects your privacy
     and is committed to protecting the privacy of your
     personal data. Cipla Digital Health Limited shall
     handle your personal data as follows:`,
    `Your personal data that we collect/ receive
     directly or from our Partner:mobile number, .,
     name, age, gender, lung health index readings
     using cough screening tool, health data including
     cough, for the purpose of Lung Health Evaluation
     program conducted by Cipla Digital Health
     Ltd.("Purpose").`,
    `We collect/ receive your personal data for:
     Presentation at scientific conferences, journal
     publications; Other publications such as social
     media, news letters, website; Use in awareness
     programs and educational purpose for betterment of
     our services.`,
    `Please refer below for providing your consent and
     check the appropriate box accordingly`,
    `DECLARATION:`,
    `I, HEREBY CONSENT to collect my personal data:`,
    `Presentation at scientific conferences, journal publications`,
    `Other publications such as social media, news letters, website.`,
    `Use in awareness programs and educational purpose for betterment of our services.`,
    `Further, I give my consent to use of my Personal
     Information/Sensitive Personal Information in
     furtherance of the Purpose. I give my consent to
     use the aforesaid statement in such form and
     manner as the Company shall deem fit for the
     Purpose. Further, I state that am not, directly or
     indirectly, related to or having any interest
     (financial or otherwise) in the Company and
     neither been influenced in any manner whatsoever
     by the Company or any its
     employees/agents/affiliates for making this
     testimonial.`,
    `I understand that my Personal Information
     collected by the Company, will be processed and
     protected by Cipla Digital Health Limited and that
     further information pertaining to the manner of
     use, storage, retention and withdrawal of my
     Personal Information can be obtained by me by
     writing to the Company at feedback@cdhl.in This
     consent shall be valid till the Purpose is served.
     I retain the right to revoke my consent at any
     time by informing the Company of the same in
     writing. In the event this document is translated
     into any other languages, the English translation
     shall prevail.`,
    `I, ALSO ACKNOWLEDGE THAT:`,
    `I have been provided with clear and
     understandable information in the Privacy Notice
     regarding handling and processing of my personal
     data.`,
    `I have been informed about my rights as a Data
     Principal under the Digital Personal Data
     Protection Act including my rights to access
     information, right to correct and erase my
     personal data, right to nominate, and right to
     grievance redressal.`,
    `I have a right to withdraw my consent at any
     time and this withdrawal will not affect the
     lawfulness of data processing that occurred
     before the withdrawal date.`,
    `My personal data may be shared with third
     parties as necessary for the purpose stated
     above.`,
    `My consent is valid until I choose to withdraw it.`,
    `I state that the above declarations are true to my
     personal knowledge and belief and I firmly believe
     the same to be correct.`
  ],
  hi: [
    `सिप्ला डिजिटल हेल्थ लिमिटेड (जिसे आगे “हम” या “हमारा” या “हमें” कहा गया है) आपकी गोपनीयता का सम्मान करता है और आपके व्यक्तिगत डेटा की गोपनीयता की सुरक्षा के लिए प्रतिबद्ध है। सिप्ला डिजिटल हेल्थ लिमिटेड आपके व्यक्तिगत डेटा को निम्न प्रकार से संभालेगा:`,
    `आपका व्यक्तिगत डेटा जो हम सीधे या हमारे पार्टनर से एकत्र/प्राप्त करते हैं: मोबाइल नंबर, नाम, उम्र, लिंग, कफ स्क्रीनिंग टूल का उपयोग कर फेफड़ों के स्वास्थ्य सूचकांक रीडिंग, स्वास्थ्य डेटा जिसमें कफ शामिल है, सिप्ला डिजिटल हेल्थ लिमिटेड द्वारा संचालित फेफड़ों के स्वास्थ्य मूल्यांकन कार्यक्रम ("उद्देश्य") के लिए।`,
    `हम आपके व्यक्तिगत डेटा को निम्न उद्देश्यों के लिए एकत्र/प्राप्त करते हैं:`,
    `कृपया अपनी सहमति प्रदान करने के लिए नीचे देखें और उपयुक्त बॉक्स पर चेक करें।`,
    `घोषणा:`,
    `मैं, यहां, मेरे व्यक्तिगत डेटा को एकत्र करने की सहमति देता हूं:`,
    `वैज्ञानिक सम्मेलनों में प्रस्तुति, जर्नल प्रकाशन`,
    `अन्य प्रकाशन जैसे सोशल मीडिया, न्यूज़लेटर, वेबसाइट।`,
    `जागरूकता कार्यक्रमों और शैक्षिक उद्देश्यों में उपयोग के लिए हमारे सेवाओं के सुधार के लिए।`,
    `इसके अतिरिक्त, मैं उद्देश्य के प्रवर्तन में मेरे व्यक्तिगत जानकारी/संवेदनशील व्यक्तिगत जानकारी के उपयोग की सहमति देता हूं। मैं सहमति देता हूं कि कंपनी इस कथन का उपयोग उद्देश्य के अनुसार उचित रूप में और तरीके से कर सकती है। इसके अलावा, मैं यह घोषित करता हूं कि मैं सीधे या अप्रत्यक्ष रूप से कंपनी से संबंधित नहीं हूं या किसी प्रकार की (वित्तीय या अन्य) रुचि नहीं रखता हूं, और यह कि इस गवाही को बनाने के लिए कंपनी या उसके किसी भी कर्मचारी/एजेंट/संबद्ध द्वारा मुझ पर किसी भी प्रकार का प्रभाव नहीं डाला गया है।`,
    `मैं समझता हूं कि कंपनी द्वारा एकत्र की गई मेरी व्यक्तिगत जानकारी सिप्ला डिजिटल हेल्थ लिमिटेड द्वारा संसाधित और संरक्षित की जाएगी और मेरी व्यक्तिगत जानकारी के उपयोग, संग्रहण, संरक्षण और वापसी से संबंधित अधिक जानकारी मुझे कंपनी को feedback@cdhl.in पर लिखकर प्राप्त हो सकती है। यह सहमति तब तक मान्य होगी जब तक उद्देश्य पूरा नहीं हो जाता। मैं कंपनी को लिखित में सूचित करके अपनी सहमति को कभी भी वापस लेने का अधिकार रखता हूं। यदि इस दस्तावेज़ का अनुवाद किसी अन्य भाषा में किया जाता है, तो अंग्रेजी अनुवाद को प्राथमिकता दी जाएगी।`,
    `मैं यह भी स्वीकार करता हूं कि:`,
    `मुझे गोपनीयता नोटिस में मेरे व्यक्तिगत डेटा के प्रबंधन और प्रसंस्करण के बारे में स्पष्ट और समझने योग्य जानकारी प्रदान की गई है।`,
    `मुझे डिजिटल व्यक्तिगत डेटा संरक्षण अधिनियम के तहत डेटा प्रमुख के रूप में मेरे अधिकारों के बारे में सूचित किया गया है, जिसमें जानकारी प्राप्त करने का अधिकार, व्यक्तिगत डेटा को सही करने और मिटाने का अधिकार, नामित करने का अधिकार, और शिकायत निवारण का अधिकार शामिल है।`,
    `मुझे किसी भी समय अपनी सहमति वापस लेने का अधिकार है और यह वापसी वापसी की तारीख से पहले किए गए डेटा प्रसंस्करण की वैधता को प्रभावित नहीं करेगी।`,
    `मेरे व्यक्तिगत डेटा को ऊपर बताए गए उद्देश्य के लिए आवश्यकतानुसार तीसरे पक्षों के साथ साझा किया जा सकता है।`,
    `मेरी सहमति तब तक मान्य है जब तक मैं इसे वापस लेने का विकल्प नहीं चुनता।`,
    `मैं घोषणा करता हूं कि उपरोक्त घोषणाएं मेरे व्यक्तिगत ज्ञान और विश्वास के अनुसार सत्य हैं और मुझे विश्वास है कि वे सही हैं।`
  ]
}
const Login = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isAgreed, setIsAgreed] = useState(true);
  const [iswhatsappAgreed, setIsWhatsAppAgreed] = useState(true);
  const [authResult, setAuthResult] = useState(null);
  const [verifyotp, setVerifyOtp] = useState(false);
  const [isnew, setIsnew] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [urlRedirect, setUrlRedirect] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const phoneInputRef = useRef(null);
  const { state } = useLocation();
  const handleIconClick = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };
  const authenticateUser = async () => {
    try {
      const response = await axios.post(
        `${AUTH_BASE_URL}/api/v1/authenticate`,
        {
          application_id: process.env.REACT_APP_APPLICATION_ID,
          fingerprint: "goldfish_arm64",
          device_type: "MOBILE",
          device_name: "google",
          os: "android",
          os_version: "14",
          browser: "",
          browser_version: "",
          application_version: "5.8",
          last_active: "2022-03-26 16:39:59",
        }
      );

      if (response.data.response.code === 200) {
        setAuthResult(response.data.data);
      } else {
        console.error("Unexpected response code:", response.data.response.code);
      }
    } catch (error) {
      console.error("Authentication error:", error);
    }
  };
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const redirectValue = searchParams.get("url_redirect");
    if (redirectValue) {
      setUrlRedirect(redirectValue);
    }
    // else{
    //   localStorage.removeItem('languageSelection');
    // }
  }, []);
  useEffect(() => {
    if (phoneInputRef.current) {
      phoneInputRef.current.focus();
    }
  }, []);
  useEffect(() => {
    const requestPhoneNumberHint = async () => {
      if (window.navigator && window.navigator.credentials) {
        try {
          const phoneNumberCredential = await window.navigator.credentials.get({
            otp: { transport: ['sms'] },
            mediation: 'optional',
          });

          if (phoneNumberCredential) {
            console.log('Phone number:', phoneNumberCredential.id);
          }
        } catch (error) {
          console.error('Error fetching phone number hint:', error);
        }
      }
    };

    requestPhoneNumberHint();
  }, []);
  useEffect(() => {
    const token = localStorage.getItem("accesstoken");
    const swaasaLogin = localStorage.getItem('swaasaLogin');
    const carePlixLogin = localStorage.getItem('careplixLogin');
    if (token) {
      if (swaasaLogin === 'true') {
        // If token exists and swaasaLogin is 'true', redirect to lung health test
        const path =
          state?.linkFrom === "educator"
            ? "/lung-health-test/educator"
            : "/lung-health-test/user";
        navigate(path);

      } else if (carePlixLogin === 'true') {
        const path =
          state?.linkFrom === "educator"
            ? "/lung-health-assessment/educator"
            : "/lung-health-assessment/user";
        navigate(path);
      } else {
        // If token exists but swaasaLogin is not 'true', redirect to home
        navigate("/");
      }
    } else {
      // If no token, authenticate the app
      authenticateUser();
    }
  }, [navigate]);

  // useEffect(() => {
  //   const requestPhoneNumberHint = async () => {
  //     if ('OTPCredential' in window) {
  //       try {
  //         const abortController = new AbortController();
  //         const hint = await navigator.credentials.get({
  //           otp: { transport: ['sms'] },
  //           signal: abortController.signal
  //         });

  //         // Clean the phone number to only keep digits
  //         if (hint && hint.code) {
  //           const cleanedNumber = hint.code.replace(/\D/g, '');
  //           // If the number starts with country code (e.g., +91), remove it
  //           const finalNumber = cleanedNumber.startsWith('91') 
  //             ? cleanedNumber.slice(2) 
  //             : cleanedNumber;

  //           // Only set if it's a 10-digit number
  //           if (finalNumber.length === 10) {
  //             setPhoneNumber(finalNumber);
  //             // Optional: Trigger any validation or submit logic
  //             if (phoneInputRef.current) {
  //               phoneInputRef.current.blur();
  //             }
  //           }
  //         }

  //         // Cleanup after 5 minutes
  //         setTimeout(() => {
  //           abortController.abort();
  //         }, 5 * 60 * 1000);

  //       } catch (error) {
  //         if (error.name !== 'AbortError') {
  //           console.error('Error fetching phone number hint:', error);
  //         }
  //       }
  //     }
  //   };

  //   // Request the phone hint when component mounts
  //   requestPhoneNumberHint();
  // }, []);
  // const handleLoginLClick = () => {
  //   navigate("/otpverify");
  // };
  const handleLoginLClick = async () => {
    try {
      const response = await axios.post(
        `${AUTH_BASE_URL}/api/v2/user/login_register`,
        {
          phone_code: "+91",
          phone_number: phoneNumber,
          terms_conditions: isAgreed,
        },
        {
          headers: {
            timestamp: Date.now().toString(),
            accesstoken: authResult?.access_token,
          },
        }
      );

      if (
        response?.data?.response?.code === 200 &&
        response?.data?.response?.status === "success"
      ) {
        if (urlRedirect === "swaasa") {
          sendEventsToAnalyticsSystems("swaasa_login_tap", {
            channel: "web",
            phone_code: "+91",
            phone_number: phoneNumber,
            terms_conditions: isAgreed,
            tapType: "Proceed",
            language: localStorage.getItem("languageSelection"),
          });
        } else if (urlRedirect === "careplix") {
          sendEventsToAnalyticsSystems("careplix_login_screen_tap", {
            channel: "web",
            phone_code: "+91",
            phone_number: phoneNumber,
            terms_conditions: isAgreed,
            tap_type: "Proceed",
            screen_name: 'LOGIN',
            language: localStorage.getItem("languageSelectionCarePlix"),
          });
        } else {
          sendEventsToAnalyticsSystems("on_login_tap", {
            phone_code: "+91",
            phone_number: phoneNumber,
            terms_conditions: isAgreed,
            tapType: "continue",
          });
        }

        setVerifyOtp(true);
        setIsnew(response?.data?.data?.is_new);
      } else {
      }
    } catch (error) {
      console.error("Login/Register error:", error);
    }
  };

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value.replace(/\D/g, ""); // Remove non-digit characters
    if (value.length <= 10) {
      setPhoneNumber(value);
      if (value.length === 10) {
        // Close the keyboard by removing focus from the input
        phoneInputRef.current.blur();
        setIsFocused(false);
      }
    }
  };
  const handleInputFocus = () => {
    setIsFocused(true);
  };

  const handleInputBlur = () => {
    setIsFocused(false);
  };

  const getInputClassName = () => {
    let className =
      "w-full border rounded-lg py-4 transition-all duration-300 outline-none ";

    if (isFocused) {
      className += "p-2 ";
      if (phoneNumber.length > 0 && phoneNumber.length < 10) {
        className += "border-[#72B53D] bg-[#F1F9EA] ";
      } else {
        className += "border-gray-300 ";
      }
    } else {
      className += "p-0 border-gray-300 ";
    }

    return className;
  };
  useEffect(() => {
    if (urlRedirect === "swaasa") {
      sendEventsToAnalyticsSystems("swaasa_login", {
        channel: "web",
        language: localStorage.getItem("languageSelection"),
      });
      localStorage.removeItem("languageSelectionCarePlix")
      trackPageview();
    } else if (urlRedirect === "careplix") {
      sendEventsToAnalyticsSystems("careplix_login_screen", {
        channel: "web",
        screen_name: 'LOGIN',
        language: localStorage.getItem("languageSelectionCarePlix"),
      });
      trackPageview();
      localStorage.removeItem("languageSelection")
    } else {
      sendEventsToAnalyticsSystems("on_login_screen", {
        utm_source: "breathing_exercise_web",
      });
      trackPageview();
      // localStorage.removeItem('languageSelection')
    }
  }, [urlRedirect]);
  const isButtonEnabled = phoneNumber.length === 10 && isAgreed;
  const isHindiLang = (localStorage.getItem('languageSelection') || localStorage.getItem("languageSelectionCarePlix")) === 'HI'
  const language = isHindiLang ? 'hi' : 'en';
  return (
    <>
      {urlRedirect === "swaasa" || urlRedirect === "careplix" ? (
        <LungHealthTestNav />
      ) : (
        <InstallAppHeader />
      )}

      {/* <div className="w-full">
        <Carousel />
      </div> */}

      <div
        className="w-full"
      // z-20 bottom-0 start-0  rounded-t-5xl  fixed
      >
        {/* <div className="bg-gradient-to-r from-purple-400 to-purple-600 h-[100px]  rounded-t-3xl text-white font-sm text-center p-2 absolute top-[-40px] -z-10 w-full">
          <p className="text-[12px] font-medium pt-1  flex justify-center">
            Install app now and earn{" "}
            <img src={BfCoins} className="w-[20px] h-[20px]" />
            <span className="text-[12px] font-bold">500 breathefree coins</span>
          </p>
        </div> */}
        <div className="flex justify-between items-center mt-20 relative">
          {!verifyotp ? (
            <div className="pl-6 w-80">
              <p className="text-2xl text-[#72B53D] font-semibold">
                {!isHindiLang
                  ? `Welcome to Breathefree`
                  : `ब्रीथफ्री में आपका स्वागत है!`}
              </p>
              <p className="text-lg mt-3 text-[#6F6F6F] font-medium">
                {!isHindiLang
                  ? `Login to access your lung health journey securely`
                  : "अपनी फेफड़ों की स्वास्थ्य यात्रा तक सुरक्षित रूप से पहुंचने के लिए लॉगिन करें"}
              </p>
            </div>
          ) : (
            <div className="pl-6 w-80">
              <p className="text-2xl text-[#72B53D] font-semibold">
                {!isHindiLang ? `Enter OTP` : "ओटीपी दर्ज करें"}
              </p>
              <p className="text-lg mt-3 text-[#6F6F6F] font-medium">
                {!isHindiLang
                  ? `Sent to ${phoneNumber}`
                  : `${phoneNumber} पर ओटीपी भेजा गया है`}
              </p>
            </div>
          )}
          <div>
            <img
              className="w-[200px] h-auto"
              src={loginbanner}
              alt="Login banner"
            />
          </div>
        </div>
        {!verifyotp ? (
          <div className="max-w-md mt-5 mx-auto px-6 rounded-lg rounded-t-3xl bg-white pb-5 relative z-10 py-3">
            <div className="p-1 mt-3">
              {/* <h2 className="text-2xl font-semibold mb-2">
                Login to Breathefree
              </h2>
              <p className="mb-4 text-sm font-normal">
                Enter your phone number to receive OTP
              </p> */}
              <div className="mb-4 relative">
                <label
                  htmlFor="phoneNumber"
                  className="block text-2xl font-medium mb-4"
                >
                  {!isHindiLang ? `Mobile number` : "मोबाइल नंबर"}

                  <span className="text-red-500">*</span>
                </label>
                <div className="relative">
                  {isFocused && (
                    <span
                      className={`absolute left-3 top-1/2 transform -translate-y-1/2 text-[#1F1F1F] transition-opacity duration-300 ${isFocused ? "opacity-100" : "opacity-0"
                        }`}
                    >
                      +91
                    </span>
                  )}

                  <input
                    type="tel"
                    id="phoneNumber"
                    placeholder={isFocused ? "" : "Eg. 9000000000"}
                    className={getInputClassName()}
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange}
                    onFocus={handleInputFocus}
                    onBlur={handleInputBlur}
                    maxLength={10}
                    ref={phoneInputRef}
                    style={{ paddingLeft: isFocused ? "45px" : "8px" }}
                  />
                </div>
              </div>
              <div className="fixed bottom-5 left-5 right-5">
                {/* <div className="flex">
                  <div className="flex items-start mb-4">
                    <label className="custom-checkbox">
                      <input
                        type="checkbox"
                        id="whatsapp"
                        checked={iswhatsappAgreed}
                        onChange={(e) => setIsWhatsAppAgreed(e.target.checked)}
                      />
                      <span className="checkmark">
                        <svg viewBox="0 0 24 24">
                          <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
                        </svg>
                      </span>
                      <span className="text-[10px] font-medium">
                        I agree to give permission for WhatsApp
                      </span>
                    </label>
                  </div>
                </div> */}
                {urlRedirect === "swaasa" || urlRedirect === "careplix" ? (
                  <div className="">
                    <div className="flex items-start mb-4">
                      <div className="custom-checkbox">
                        <input
                          type="checkbox"
                          id="agreement"
                          checked={isAgreed}
                        />
                        <span className="checkmark"
                          onClick={() => setIsAgreed(!isAgreed)}
                        >
                          <svg viewBox="0 0 24 24">
                            <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
                          </svg>
                        </span>
                        {!isHindiLang ? (
                          <span className="text-[10px] font-medium w-full">
                            I confirm that I have fully read and understood the
                            contents of the{" "}
                            <Link
                              to="/privacypolicy"
                              className="text-button-bg-color"
                            >
                              Privacy Policy{" "}
                            </Link>
                            and{" "}
                            <Link
                              to="/termsofuse"
                              className="text-button-bg-color"
                            >
                              Term of use{" "}
                            </Link>
                            and I hereby consent to share my Personal Data for
                            this test to get my lung health index.{" "}
                            <button onClick={handleIconClick}>
                              <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none" className="absolute bottom-0">
                                <circle cx="8.25" cy="8.25" r="7.6875" stroke="#333333" stroke-width="1.125" />
                                <path d="M8.16411 14.1863C7.72056 14.1863 7.36129 14.0843 7.08629 13.8802C6.82016 13.6673 6.65161 13.379 6.58065 13.0153C6.51855 12.6516 6.57177 12.2391 6.74032 11.7778L7.75161 8.91694C7.87581 8.57097 7.89798 8.29153 7.81815 8.07863C7.74718 7.86573 7.5875 7.75927 7.33911 7.75927C7.14395 7.75927 6.95766 7.83024 6.78024 7.97218C6.61169 8.11411 6.45202 8.33589 6.30121 8.6375L6.04839 9.10323H5.52944L5.83548 8.53105C6.12823 7.98105 6.45202 7.57742 6.80685 7.32016C7.17056 7.0629 7.59194 6.93427 8.07097 6.93427C8.52339 6.93427 8.88266 7.04073 9.14879 7.25363C9.41492 7.45766 9.58347 7.74153 9.65444 8.10524C9.7254 8.46008 9.67218 8.87258 9.49476 9.34274L8.48347 12.2036C8.35927 12.5496 8.3371 12.829 8.41694 13.0419C8.49677 13.2548 8.65645 13.3613 8.89597 13.3613C9.28629 13.3613 9.63669 13.073 9.94718 12.4964L10.1867 12.0306H10.7056L10.4129 12.5895C10.1202 13.1484 9.79194 13.5565 9.42823 13.8137C9.06452 14.0621 8.64315 14.1863 8.16411 14.1863ZM9.14879 5.76331C8.83831 5.76331 8.58105 5.6746 8.37702 5.49718C8.18185 5.31089 8.08427 5.07137 8.08427 4.77863C8.08427 4.45927 8.20403 4.19315 8.44355 3.98024C8.68306 3.75847 8.97137 3.64758 9.30847 3.64758C9.61895 3.64758 9.87177 3.74073 10.0669 3.92702C10.271 4.10444 10.373 4.33952 10.373 4.63226C10.373 4.95161 10.2532 5.22218 10.0137 5.44395C9.78306 5.65685 9.49476 5.76331 9.14879 5.76331Z" fill="#333333" />
                              </svg>
                            </button>
                          </span>
                        ) : (
                          <span className="text-[10px] font-medium w-full">
                            मैं पुष्टि करता हूँ कि मैंने
                            <Link
                              to="/privacypolicy"
                              className="text-button-bg-color"
                            >
                              {" "}
                              गोपनीयता नीति{" "}
                            </Link>
                            और{" "}
                            <Link
                              to="/termsofuse"
                              className="text-button-bg-color"
                            >
                              उपयोग की शर्तों
                            </Link>{" "}
                            को पूरी तरह से पढ़ा और समझा है, और मैं अपनी
                            व्यक्तिगत जानकारी इस परीक्षण के लिए साझा करने की
                            सहमति देता हूँ ताकि मेरा फेफड़े का स्वास्थ्य सूचकांक
                            प्राप्त किया जा सके।{" "}<button onClick={handleIconClick}>
                              <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none" className="absolute bottom-0">
                                <circle cx="8.25" cy="8.25" r="7.6875" stroke="#333333" stroke-width="1.125" />
                                <path d="M8.16411 14.1863C7.72056 14.1863 7.36129 14.0843 7.08629 13.8802C6.82016 13.6673 6.65161 13.379 6.58065 13.0153C6.51855 12.6516 6.57177 12.2391 6.74032 11.7778L7.75161 8.91694C7.87581 8.57097 7.89798 8.29153 7.81815 8.07863C7.74718 7.86573 7.5875 7.75927 7.33911 7.75927C7.14395 7.75927 6.95766 7.83024 6.78024 7.97218C6.61169 8.11411 6.45202 8.33589 6.30121 8.6375L6.04839 9.10323H5.52944L5.83548 8.53105C6.12823 7.98105 6.45202 7.57742 6.80685 7.32016C7.17056 7.0629 7.59194 6.93427 8.07097 6.93427C8.52339 6.93427 8.88266 7.04073 9.14879 7.25363C9.41492 7.45766 9.58347 7.74153 9.65444 8.10524C9.7254 8.46008 9.67218 8.87258 9.49476 9.34274L8.48347 12.2036C8.35927 12.5496 8.3371 12.829 8.41694 13.0419C8.49677 13.2548 8.65645 13.3613 8.89597 13.3613C9.28629 13.3613 9.63669 13.073 9.94718 12.4964L10.1867 12.0306H10.7056L10.4129 12.5895C10.1202 13.1484 9.79194 13.5565 9.42823 13.8137C9.06452 14.0621 8.64315 14.1863 8.16411 14.1863ZM9.14879 5.76331C8.83831 5.76331 8.58105 5.6746 8.37702 5.49718C8.18185 5.31089 8.08427 5.07137 8.08427 4.77863C8.08427 4.45927 8.20403 4.19315 8.44355 3.98024C8.68306 3.75847 8.97137 3.64758 9.30847 3.64758C9.61895 3.64758 9.87177 3.74073 10.0669 3.92702C10.271 4.10444 10.373 4.33952 10.373 4.63226C10.373 4.95161 10.2532 5.22218 10.0137 5.44395C9.78306 5.65685 9.49476 5.76331 9.14879 5.76331Z" fill="#333333" />
                              </svg>
                            </button>
                          </span>
                        )}
                      </div>
                    </div>
                    <div className=" w-full">
                      <button
                        className={` flex justify-between gap-7 items-center text-base font-semibold px-4 py-2 rounded-full w-full text-white ${isButtonEnabled
                          ? "bg-button-bg-color"
                          : "bg-[#EBEBEB] cursor-not-allowed text-[#777777]"
                          }`}
                        disabled={!isButtonEnabled}
                        onClick={handleLoginLClick}
                      >
                        {!isHindiLang ? `Proceed` : "आगे बढ़े"}
                        <span>{`>`}</span>
                      </button>
                    </div>
                    {isOpen && (
                      <div className="fixed bottom-0 left-0 w-full h-full bg-transparent bg-opacity-75 flex justify-center items-end pb-5 z-50">
                        <div className="bg-white w-11/12 md:w-3/4 lg:w-1/2 h-[90%] overflow-y-auto rounded-lg shadow-lg relative">
                          <div
                            className="absolute top-4 right-4 cursor-pointer"
                            onClick={handleClose}
                          >
                            <img src={closeicon} />
                          </div>
                          <div className="bg-white p-8 rounded-lg shadow-lg">
                            {/* <h2 className="text-2xl font-bold mb-4">
                              Consent Form
                            </h2> */}
                            <p className="mb-4">
                              {consentData?.[language]?.[0]}
                            </p>
                            <p className="mb-4">
                              {consentData?.[language]?.[1]}
                            </p>
                            <p className="mb-4">
                              {consentData?.[language]?.[2]}
                            </p>
                            <p className="mb-4">
                              {consentData?.[language]?.[3]}
                            </p>
                            <h3 className="text-xl font-bold mb-4">
                              {consentData?.[language]?.[4]}
                            </h3>
                            <p className="mb-4">
                              <strong>
                                {consentData?.[language]?.[5]}
                              </strong>
                            </p>
                            <ul className="list-disc pl-6 mb-4">
                              <li>
                                {consentData?.[language]?.[6]}
                              </li>
                              <li>
                                {consentData?.[language]?.[7]}
                              </li>
                              <li>
                                {consentData?.[language]?.[8]}
                              </li>
                            </ul>
                            <p className="mb-4">
                              {consentData?.[language]?.[9]}
                            </p>
                            <p className="mb-4">
                              {consentData?.[language]?.[10]}
                            </p>
                            <h3 className="text-xl font-bold mb-4">
                              {consentData?.[language]?.[11]}
                            </h3>
                            <ul className="list-disc pl-6 mb-4">
                              <li>
                                {consentData?.[language]?.[12]}
                              </li>
                              <li>
                                {consentData?.[language]?.[13]}
                              </li>
                              <li>
                                {consentData?.[language]?.[14]}
                              </li>
                              <li>
                                {consentData?.[language]?.[15]}
                              </li>
                              <li>
                                {consentData?.[language]?.[16]}
                              </li>
                            </ul>
                            <p className="mb-4">
                              {consentData?.[language]?.[17]}
                            </p>
                          </div>
                          <div className="sticky bottom-0 bg-white px-4 py-2 shadow-top">
                            <button
                              onClick={handleClose}
                              className="flex justify-between  bg-button-bg-color hover:bg-button-bg-color w-full font-semibold px-4 py-2 rounded-full text-white"
                            >
                              {isHindiLang ? `मैं सहमत हूं` : `I Agree`}
                              <span>{`>`}</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="flex justify-between ">
                    <div className="flex items-start mb-4">
                      <label className="custom-checkbox">
                        <input
                          type="checkbox"
                          id="agreement"
                          checked={isAgreed}
                          onChange={(e) => setIsAgreed(e.target.checked)}
                        />
                        <span className="checkmark">
                          <svg viewBox="0 0 24 24">
                            <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
                          </svg>
                        </span>
                        {!isHindiLang ? (
                          <span className="text-[10px] font-medium">
                            I agree to Breathefree's
                            <Link
                              to="/privacypolicy"
                              className="text-button-bg-color"
                            >
                              Privacy Policy
                              <br />
                            </Link>
                            and{" "}
                            <Link
                              to="/termsofuse"
                              className="text-button-bg-color"
                            >
                              Terms & Conditions
                            </Link>
                          </span>
                        ) : (
                          <span className="text-[10px] font-medium">
                            आगे बढ़ते हुए, मैं ब्रीथफ्री की
                            <Link
                              to="/privacypolicy"
                              className="text-button-bg-color"
                            >
                              {" "}
                              गोपनीयता नीति{" "}
                            </Link>
                            <br /> और{" "}
                            <Link
                              to="/termsofuse"
                              className="text-button-bg-color"
                            >
                              नियम और शर्तों
                            </Link>{" "}
                            से सहमत हूं
                          </span>
                        )}
                      </label>
                    </div>

                    <button
                      className={` flex justify-between gap-7 items-center text-base font-semibold px-4 py-2 rounded-full text-white ${isButtonEnabled
                        ? "bg-button-bg-color"
                        : "bg-[#EBEBEB] cursor-not-allowed text-[#777777]"
                        }`}
                      disabled={!isButtonEnabled}
                      onClick={handleLoginLClick}
                    >
                      {!isHindiLang ? `Proceed` : "आगे बढ़े"}
                      <span>{`>`}</span>
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <OTPVerify
            phone_number={phoneNumber}
            setVerifyOtp={setVerifyOtp}
            token={authResult?.access_token}
            isnew={isnew}
            isAgreed={isAgreed}
            iswhatsappAgreed={iswhatsappAgreed}
            urlRedirect={urlRedirect}
            urlRedirectFrom={state?.linkFrom}
          />
        )}
      </div>
    </>
  );
};

export default Login;
