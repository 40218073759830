import React, { useState, useRef, useEffect } from "react";
import LungHealthTestNav from "../LungHealthTest/LungHeathTestNav";
import profileDetail from "../../assests/images/Profiledetail.png";
import { Link, useNavigate,useLocation } from "react-router-dom";
import axios from "axios";
import { AUTH_BASE_URL,BASE_COMMON_URL } from "../../utils/ApiConstants";
import { sendEventsToAnalyticsSystems } from "../../utils/analytics";
import SeoHelmet from "../../utils/SeoHelmet";
const ProfileCreationCarePlix = () => {
  // const [formData, setFormData] = useState(() => {
  //   const savedData = localStorage.getItem("profileDetails");
  //   return savedData
  //     ? JSON.parse(savedData)
  //     : {
  //         full_name: "",
  //         age: "",
  //         gender: "",
  //         invite_code:""
  //       };
  // });
  const [formData, setFormData] = useState({
    full_name: "",
    age: "",
    gender: "",
    invite_code: ""
  });
  const navigate = useNavigate();
  const [isFocused, setIsFocused] = useState(false);
  const [focusedField, setFocusedField] = useState(null);
  const [isAgreed, setIsAgreed] = useState(true);
  const full_nameRef = useRef(null);
  const ageRef = useRef(null);
  const inviteCodeRef = useRef(null);
  const [inviteCodeError, setInviteCodeError] = useState("");
  const { state } = useLocation();
  const customRadioStyles = `
    .custom-radio {
      appearance: none;
      -webkit-appearance: none;
      width: 20px;
      height: 20px;
      border: 0.5px solid #999999;
      border-radius: 50%;
      outline: none;
      transition: all 0.3s ease-in-out;
    }
    .custom-radio:checked {
      background-color: #72B53D;
      border: 2px solid #72B53D;
      box-shadow: inset 0 0 0 4px white;
    }
    .custom-radio-label {
      transition: all 0.3s ease-in-out;
    }
    .custom-radio:checked + .custom-radio-label {
      color: #72B53D;
    }
  `;
  useEffect(() => {
    sendEventsToAnalyticsSystems("careplix_user_details", {
      channel: "web",
      language: localStorage.getItem("languageSelectionCarePlix"),
      mobile: localStorage.getItem("mobileNumber"),
      user_id: localStorage.getItem("secondary_uuid"),
      utm_source: "careplix",
      screen_name:'USER_DETAILS',
    },localStorage.getItem("secondary_uuid"));
  }, []);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (name === 'invite_code') {
        if (value.length > 0 && (value.length < 4 || value.length > 7)) {
          setInviteCodeError("Invite code must be between 4 and 7 characters");
        } else {
          setInviteCodeError("");
        }
      }
  };
  const getInputClassName = (fieldName) => {
    let className =
      "w-full border rounded-lg py-4 transition-all duration-300 outline-none ";

    if (focusedField === fieldName) {
      className += "p-2 ";
      if (formData[fieldName] && formData[fieldName].length > 0) {
        className += "border-[#72B53D] bg-[#F1F9EA] ";
      } else {
        className += "border-gray-300 ";
      }
    } else {
      className += "p-0 border-gray-300 ";
    }

    return className;
  };

  const isButtonEnabled =
    formData.full_name !== "" &&
    formData.age !== "" &&
    formData.gender !== "" &&
    ((state?.linkFrom === 'educator' || state?.urlRedirectFrom === 'educator') 
    ? (formData.invite_code?.length >= 4 && formData.invite_code?.length <= 7)
    : true)
     &&
    isAgreed;
  const handleSubmit = async () => {
    try {
      const accessToken = localStorage.getItem("accesstoken");
      const response = await axios.post(
        `${AUTH_BASE_URL}/api/v1/onboarding/profile_creation`,
        {
          preferred_language: localStorage.getItem('languageSelectionCarePlix'),
          full_name: formData.full_name,
          user_age: formData.age,
          gender: formData.gender,
          invite_code:null
        },
        {
          headers: {
            accesstoken: accessToken,
          },
        }
      );

      // Check if the API call was successful
      if (
        response.data &&
        response.data.response &&
        response.data.response.status === "success"
      ) {
        // Store the form data in localStorage
        try {
          const storedLang = localStorage.getItem("languageSelectionCarePlix");
          const baseUrl = process.env.REACT_APP_WEBSITE_URL;
          const redirectUrl = `${baseUrl}/lung-health-assessment/${state?.urlRedirectFrom}`;
            const carePlixResponse = await axios.get(
              `${BASE_COMMON_URL}/api/v1/careplix-services/careplix-scan-details?lang=${storedLang}&platform=web&retry_url=${encodeURIComponent(redirectUrl)}`,
              {
                headers: {
                  'accesstoken': accessToken,
                }
              }
            );
            const pwa_link = carePlixResponse?.data?.data?.pwa_link;
            navigate("/lung-health-assessment/assessment", {
              state: {
                pwa_link: pwa_link
              }
            });
          } catch (error) {
            console.error("Error fetching CarePlix details:", error);
          }
        localStorage.setItem(
          "profileDetails",
          JSON.stringify({
            full_name: formData.full_name,
            age: formData.age,
            gender: formData.gender,
            invite_code: formData.invite_code
          })
        );
        sendEventsToAnalyticsSystems("careplix_user_details_tap", {
          channel: "web",
          language: localStorage.getItem("languageSelectionCarePlix"),
          mobile: localStorage.getItem("mobileNumber"),
          name: formData.full_name,
          age: formData.age,
          gender: formData.gender,
          user_id: localStorage.getItem("secondary_uuid"),
          tap_type: "Next",
          utm_source: "careplix",
          invite_code: formData.invite_code,
          screen_name:'USER_DETAILS',
        },localStorage.getItem("secondary_uuid"));
        // Navigate to the assessment page
        // navigate("/lung-health-test/assessment");
      } else {
        // Handle API error
        console.error("API Error:", response.data);
        // You might want to show an error message to the user here
      }
    } catch (error) {
      console.error("Error:", error);
      // Handle error (e.g., show error message to user)
      if (error.response) {
        console.error(error.response.data);
        console.error(error.response.status);
        console.error(error.response.headers);
      } else if (error.request) {
        console.error(error.request);
      } else {
        console.error("Error", error.message);
      }
      // You might want to show an error message to the user here
    }
  };
  const isHindiLang = localStorage.getItem("languageSelectionCarePlix") === "HI";
  return (
    <>
    <SeoHelmet title='Profile' />
    <div className="flex flex-col">
      <style>{customRadioStyles}</style>
      <div>
        <LungHealthTestNav />
      </div>
      <div className="flex-grow">
        <div className="flex justify-between items-center relative mt-10 pl-5 ">
          {!isHindiLang ? (
            <p className=" text-xl font-semibold text-[#72B53D] w-80">
              These details will
              <br /> help us get
              <br /> accurate results
            </p>
          ) : (
            <p className=" text-xl font-semibold text-[#72B53D] w-80">
              ये जानकारी हमें सटीक
              <br /> परिणाम प्राप्त करने
              <br /> में मदद करेंगे
            </p>
          )}

          <div>
            <img
              className="w-[200px] h-auto"
              src={profileDetail}
              alt="Profile Detail"
            />
          </div>
        </div>
        <div className="px-6">
          <form className="space-y-4">
            <div className="mb-4 relative">
              <label
                htmlFor="full_name"
                className="block text-base font-medium mb-1"
              >
                {!isHindiLang
                  ? `Full Name`
                  : `पूरा नाम`}{" "}
                <span className="text-red-500">*</span>
              </label>
              <div className="relative">
                <input
                  type="text"
                  id="full_name"
                  name="full_name"
                  ref={full_nameRef}
                  placeholder={
                    focusedField === "full_name" ? "" : "Enter your full name"
                  }
                  className={`${getInputClassName("full_name")}bg-[#FBFBFB]`}
                  value={formData.full_name}
                  onChange={handleInputChange}
                  onFocus={() => setFocusedField("full_name")}
                  onBlur={() => setFocusedField(null)}
                  style={{ paddingLeft: "8px" }}
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="age"
                className="block text-sm font-medium text-gray-700"
              >
                {!isHindiLang
                  ? `Age`
                  : `उम्र`}
                <span className="text-red-500">*</span>
              </label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <input
                  type="number"
                  name="age"
                  id="age"
                  ref={ageRef}
                  className={`${getInputClassName("age")}bg-[#FBFBFB]`}
                  placeholder={focusedField === "age" ? "" : "Eg. 24"}
                  value={formData.age}
                  onChange={handleInputChange}
                  onFocus={() => setFocusedField("age")}
                  onBlur={() => setFocusedField(null)}
                  style={{ paddingLeft: "8px" }}
                />
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                {!isHindiLang
                  ? ` Gender`
                  : `लिंग`}
                <span className="text-red-500">*</span>
              </label>
              {/* <div className="flex space-x-4">
                {["Male", "Female"].map((gender) => (
                  <label key={gender} className="flex-1">
                    <div
                      className={`flex items-center border rounded-md p-4 cursor-pointer ${
                        formData.gender === gender
                          ? "border-[#72B53D]"
                          : "border-gray-300 bg-gray"
                      }`}
                    >
                      <input
                        type="radio"
                        name="gender"
                        value={gender}
                        checked={formData.gender === gender}
                        onChange={handleInputChange}
                        className="form-radio h-4 w-4 text-[#72B53D] bg-gray-400 border-gray-300 focus:ring-[#72B53D]"
                      />
                      <span className="ml-2 text-base font-medium">{gender}</span>
                    </div>
                  </label>
                ))}
              </div> */}
              <div className="flex space-x-4">
                {[
                  { value: "Male", labelEn: "Male", labelHi: "पुरुष" },
                  { value: "Female", labelEn: "Female", labelHi: "महिला" },
                ].map((gender) => (
                  <label key={gender.value} className="flex-1">
                    <div
                      className={`flex items-center border rounded-md p-4 cursor-pointer ${
                        formData.gender === gender.value
                          ? "border-[#72B53D]"
                          : "border-[#EBEBEB] bg-[#FBFBFB]"
                      }`}
                    >
                      <input
                        type="radio"
                        name="gender"
                        value={gender.value}
                        checked={formData.gender === gender.value}
                        onChange={handleInputChange}
                        className="custom-radio form-radio h-4 w-4 text-[#72B53D] bg-white border-gray-300 focus:ring-[#72B53D]"
                      />
                      <span className="ml-2 text-base font-medium custom-radio-label">
                        {!isHindiLang ? gender.labelEn : gender.labelHi}
                      </span>
                    </div>
                  </label>
                ))}
              </div>
            </div>
            {(state?.linkFrom === "educator" ||
                state?.urlRedirectFrom === "educator") && (
                <div className="mb-4 relative">
                  <label
                    htmlFor="invite_code"
                    className="block text-base font-medium mb-1"
                  >
                    {!isHindiLang
                      ? `Invite Code` // Removed "Optional" since it's mandatory for educators
                      : `आमंत्रण कोड`}{" "}
                    <span className="text-red-500">*</span>
                  </label>
                  <div className="relative">
                    <input
                      type="text"
                      id="invite_code"
                      name="invite_code"
                      ref={inviteCodeRef}
                      placeholder={
                        focusedField === "invite_code"
                          ? ""
                          : "Enter Invite Code"
                      }
                      className={`${getInputClassName(
                        "invite_code"
                      )}bg-[#FBFBFB]`}
                      value={formData.invite_code}
                      onChange={handleInputChange}
                      onFocus={() => setFocusedField("invite_code")}
                      onBlur={() => {
                        setFocusedField(null);
                        // Validate invite code length on blur
                        if (
                          formData.invite_code &&
                          (formData.invite_code.length < 4 ||
                            formData.invite_code.length > 7)
                        ) {
                          setInviteCodeError(
                            "Invite code must be between 4 and 7 characters"
                          );
                        } else {
                          setInviteCodeError("");
                        }
                      }}
                      style={{ paddingLeft: "8px" }}
                    />
                  </div>
                  {inviteCodeError && (
                    <p className="text-red-500 text-sm mt-1">
                      {inviteCodeError}
                    </p>
                  )}
                </div>
              )}
          </form>
        </div>
      </div>
      <div className="mt-2 bottom-0 left-0 right-0 bg-white p-4 ">
        <div className="flex items-start mb-4">
          <label className="custom-checkbox">
            <input
              type="checkbox"
              id="agreement"
              checked={isAgreed}
              onChange={(e) => setIsAgreed(e.target.checked)}
            />
            <span className="checkmark">
              <svg viewBox="0 0 24 24">
                <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
              </svg>
            </span>
            {!isHindiLang ? (
              <span className="text-[10px] font-medium">
                I consent to Breathefree using my voluntarily shared health{" "}
                <br />
                information for a personalized experience. I may be and <br />
                contacted by the Breathefree team.
              </span>
            ) : (
              <span className="text-[10px] font-medium">
                मैं व्यक्तिगत अनुभव के लिए अपनी स्वेच्छा से साझा की गई स्वास्थ्य <br />
                जानकारी का उपयोग करने के लिए ब्रीथफ्री को सहमति देता हूं। फीडबैक <br />
                के लिए ब्रीथफ्री टीम मुझसे संपर्क कर सकती है
              </span>
            )}
          </label>
        </div>
        <div className=" w-full">
          <div
            onClick={handleSubmit}
            className={`flex justify-between px-4 py-2 bg-button-bg-color text-white font-semibold text-xl rounded-full w-full ${
              isButtonEnabled
                ? "bg-button-bg-color"
                : "bg-gray-300 cursor-not-allowed text-[#969696]"
            }`}
          >
            <button type="button" disabled={!isButtonEnabled}>
              {!isHindiLang ? `Next` : `आगे बढ़े`}
            </button>
            <span className="text-2xl">{`>`}</span>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default ProfileCreationCarePlix;
