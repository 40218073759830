import React, { useEffect, useState, useRef } from "react";
import premiumcrown from "../assests/icons/premiumcrown.png";
import Loader from "./exercise/Loader";
import BreathingExerciseFAQ from "./exercise/BreathingExerciseFAQ";
import TermsandprivacySection from "./exercise/TermsandprivacySection";
import { useNavigate } from "react-router-dom";
import close from "../assests/icons/Close.png";
import { BASE_COMMON_URL } from "../utils/ApiConstants";
import { sendEventsToAnalyticsSystems } from "../utils/analytics";
import axios from 'axios';
const SubscriptionPage = () => {
  const [subscription, setSubscription] = useState(null);
  const [selected, setSelected] = useState(true);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [verifySign,setVerifySign]=useState([]);
  const priceSectionRef = useRef(null);
  const access_Token = localStorage.getItem("accesstoken");
  const navigate = useNavigate();
  const requestOptions = {
    headers: {
      accesstoken: access_Token,
    },
  };
 const fetchPremiumExerciseData = () => {
  axios.get(`${BASE_COMMON_URL}/api/v5/vitals-tracking/product-families`, {
    params: {
      service_type: 'BREATHING_EXERCISE'
    },
    ...requestOptions
  })
  .then(response => {
    if (response.data.response.code === 200) {
      const breathingExercisePlan = response.data.data.find(
        plan => plan.service_name === "Breathing Exercise"
      );
      setSubscription(breathingExercisePlan);
      setSelectedPlan(breathingExercisePlan?.product_family_plans_onetime[0]);
    }
  })
  .catch(error => {
    if (error.response && error.response.status === 401) {
      localStorage.clear();
      navigate('/login');
    } else {
      console.error('An error occurred:', error);
    }
  });
};
  useEffect(() => {
    fetchPremiumExerciseData();
  }, []);
  const scrollToPriceSection = () => {
    priceSectionRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  const mobile_number = localStorage.getItem("mobileNumber");

  const createRazorpayOrder = async () => {
    try {
      const response = await fetch(
        `${BASE_COMMON_URL}/api/v5/payment/checkout?platform=web`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            accesstoken: access_Token,
          },
          body: JSON.stringify({
            id: selectedPlan.id,
            plan_name: selectedPlan.plan_name,
            internal_plan_name: selectedPlan.internal_plan_name,
            description: selectedPlan.description,
            scans_per_day: selectedPlan.scans_per_day,
            emergency_scans_status: selectedPlan.emergency_scans_status,
            emergency_scans_per_day: selectedPlan.emergency_scans_per_day,
            paid: selectedPlan.paid,
            billing_frequency: selectedPlan.billing_frequency,
            display_pricing: selectedPlan.display_pricing,
            discount: selectedPlan.discount,
            discountedpricing: selectedPlan.discountedpricing,
            final_pricing: selectedPlan.final_pricing,
            monthly_pricing: selectedPlan.monthly_pricing,
            plan_duration: selectedPlan.plan_duration,
            number_of_days: selectedPlan.number_of_days,
            show_as_recommended_plan: selectedPlan.show_as_recommended_plan,
            status: selectedPlan.status,
            user_experience: selectedPlan.user_experience,
            product_type: "BREATHING_EXERCISE",
            amount: selectedPlan.final_pricing,
            currency: "INR",
            family_plan_id: selectedPlan.id,
            _checkout: "1",
            payment_type: "prepay",
          }),
        }
      );
      if (!response.ok) {
        throw new Error("Failed to create Razorpay order");
      }

      return await response.json();
    } catch (error) {
      console.error("Error creating Razorpay order:", error);
      throw error;
    }
  };
  // const handlePayment = async () => {
  //   try {
  //     // Create an order on your server
  //     const orderResponse = await createRazorpayOrder();
  //     console.log("Order response:", orderResponse);
  //     const order = orderResponse?.data;
  
  //     // Check if we have a valid order ID
  //     if (order && order.order_id) {
  //       sendEventsToAnalyticsSystems("exercise_subs_tap", {
  //         TapType: "subscribe",
  //         PlanTapValue: selectedPlan.plan_name,
  //       });
  //       const options = {
  //         key: "rzp_test_b81AdYMSZAul5G", // Replace with your Razorpay Key ID
  //         amount: order.amount,
  //         currency: order.currency,
  //         name: "BreatheFree",
  //         description: "Premium Subscription",
  //         order_id: order.order_id,
  //         handler: async function (response) {
  //           // Handle the success payment
  //           console.log(response,"response")
  //           try {
  //             const verifyResponse = await axios.post(
  //               `${BASE_COMMON_URL}/api/v5/payment/verify-signature`,
  //               {
  //                 payment_id: response.razorpay_payment_id,
  //                 order_id: response.razorpay_order_id,
  //                 signature: response.razorpay_signature
  //               },
  //               {
  //                 headers: {
  //                   'Content-Type': 'application/json',
  //                   'accesstoken': access_Token // Make sure access_Token is available in this scope
  //                 }
  //               }
  //             );
  //             setVerifySign(verifyResponse.data);
  //             console.log("Signature verification result:", verifyResponse.data);
  //             // Handle successful verification (e.g., update UI, navigate to success page)
  //             navigate("/payementsuccess", { state: { verificationData: verifyResponse.data } });
  //           }catch (error) {
  //             console.error("Error verifying signature:", error);
  //             handlePaymentError(error, order.order_id);
  //           }
  //           // You can add your logic here to update the user's subscription status
  //         },
  //         modal: {
  //           ondismiss: async function() {
  //             console.log('Payment cancelled by user');
  //             try {
  //               const cancelResponse = await axios.post(
  //                 `${BASE_COMMON_URL}/api/v5/payment/verify-signature`,
  //                 {
  //                   error: "Payment cancelled by user",
  //                   order_id: order.order_id
  //                 },
  //                 {
  //                   headers: {
  //                     'Content-Type': 'application/json',
  //                     'accesstoken': access_Token
  //                   }
  //                 }
  //               );
  //               console.log("Cancellation reported:", cancelResponse.data);
  //             } catch (error) {
  //               console.error("Error reporting cancellation:", error);
  //             }
  //           }
  //         },
  //         prefill: {
  //           email: order.prefill?.email,
  //           contact: order.prefill?.contact,
  //           name: order.prefill?.name,
  //         },
  //         theme: { color: '#53a20e' },
  //       };
  
  //       console.log(options, "options");
  //       const paymentObject = new window.Razorpay(options);
  //       paymentObject.open();
  //     } else {
  //       console.error("Invalid order response:", orderResponse);
  //       alert("Failed to create order. Please try again.");
  //     }
  //   } catch (error) {
  //     console.error("Error in handlePayment:", error);
  //     alert("An error occurred. Please try again.");
  //   }
  // };
  // const handlePaymentError = async (error, orderId) => {
  //   console.error("Payment error:", error);
  //   try {
  //     const errorResponse = await axios.post(
  //       `${BASE_COMMON_URL}/api/v5/payment/verify-signature`,
  //       {
  //         error: error.message || "Payment failed",
  //         order_id: orderId
  //       },
  //       {
  //         headers: {
  //           'Content-Type': 'application/json',
  //           'accesstoken': access_Token
  //         }
  //       }
  //     );
  //     console.log("Error reported:", errorResponse.data);
  //     alert("Payment failed. Please try again.");
  //   } catch (reportError) {
  //     console.error("Error reporting payment failure:", reportError);
  //   }
  // };
  // const createRazorpayOrder = async () => {
  //   // Make an API call to your server to create a Razorpay order
  //   // Return the order object received from Razorpay
  //   // This is a placeholder implementation
  //   return { id: "order_123456" };
  // };

  // const createRazorpayOrder = async () => {
  //   const response = await fetch('/create-razorpay-order', {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify({
  //       amount: subscription.product_family_plans_onetime[0].final_pricing
  //     }),
  //   });
  //   return response.json();
  // };
  // const createRazorpayOrder = async () => {
  //   try {
  //     const response = await fetch("http://localhost:3005/create-order", {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         amount: subscription.product_family_plans_onetime[0].final_pricing,
  //         currency: "INR",
  //       }),
  //     });

  //     if (!response.ok) {
  //       throw new Error("Failed to create Razorpay order");
  //     }

  //     return await response.json();
  //   } catch (error) {
  //     console.error("Error creating Razorpay order:", error);
  //     throw error;
  //   }
  // };
  const handlePayment = async () => {
    try {
      const orderResponse = await createRazorpayOrder();
      const order = orderResponse?.data;
  
      if (order && order.order_id) {
        sendEventsToAnalyticsSystems("exercise_subs_tap", {
          TapType: "subscribe",
          PlanTapValue: selectedPlan.plan_name,
          // price: selectedPlan.final_pricing
          price: `₹${selectedPlan.final_pricing}`,
          utm_source  :'breathing_exercise_web'
        },localStorage.getItem("secondary_uuid"));
  
        const options = {
          key: process.env.REACT_APP_RAZORPAY_API_KEY,
          amount: order.amount,
          currency: order.currency,
          name: "BreatheFree",
          description: "Premium Subscription",
          order_id: order.order_id,
          // handler: async function (response) {
          //   console.log(response, "response");
          //   try {
          //     const verifyResponse = await axios.post(
          //       `${BASE_COMMON_URL}/api/v5/payment/verify-signature`,
          //       {
          //         payment_id: response.razorpay_payment_id,
          //         order_id: response.razorpay_order_id,
          //         signature: response.razorpay_signature
          //       },
          //       {
          //         headers: {
          //           'Content-Type': 'application/json',
          //           'accesstoken': access_Token
          //         }
          //       }
          //     );
          //     setVerifySign(verifyResponse.data);
          //     console.log("Signature verification result:", verifyResponse.data);
          //     navigate("/payementsuccess", { state: { verificationData: verifyResponse.data } });
          //   } catch (error) {
          //     console.error("Error verifying signature:", error);
          //     handlePaymentError(error, order.order_id);
          //   }
          // },
          handler: async function (response) {
        
        try {
          const verifyResponse = await axios.post(
            `${BASE_COMMON_URL}/api/v5/payment/verify-signature`,
            {
              payment_id: response.razorpay_payment_id,
              order_id: response.razorpay_order_id,
              signature: response.razorpay_signature
            },
            {
              headers: {
                'Content-Type': 'application/json',
                'accesstoken': access_Token
              }
            }
          );
          
          if (verifyResponse.data.response.status === "success") {
            setVerifySign(verifyResponse.data);
            navigate("/payementsuccess", { state: { verificationData: verifyResponse.data } });
          } else {
            console.error("Verification failed:", verifyResponse.data);
            alert("Payment verification failed. Please contact support.");
          }
        } catch (error) {
          console.error("Error during verification:", error.response?.data || error.message);
          handlePaymentError(error, response.razorpay_order_id);
        }
      },
          modal: {
            ondismiss: async function() {
              console.log('Payment cancelled by user');
              try {
                const cancelResponse = await axios.post(
                  `${BASE_COMMON_URL}/api/v5/payment/verify-signature`,
                  {
                    error: "Payment cancelled by user",
                    order_id: order.order_id
                  },
                  {
                    headers: {
                      'Content-Type': 'application/json',
                      'accesstoken': access_Token
                    }
                  }
                );
                console.log("Cancellation reported:", cancelResponse.data);
                // Handle cancellation (e.g., show a message to the user)
                alert("Payment was cancelled. Please try again if you wish to subscribe.");
              } catch (error) {
                console.error("Error reporting cancellation:", error);
              }
            }
          },
          prefill: {
            email: order.prefill?.email,
            contact: order.prefill?.contact,
            name: order.prefill?.name,
          },
          theme: { color: '#53a20e' },
        };
  
        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
      } else {
        console.error("Invalid order response:", orderResponse);
        alert("Failed to create order. Please try again.");
      }
    } catch (error) {
      console.error("Error in handlePayment:", error);
      alert("An error occurred. Please try again.");
    }
  };
  
  const handlePaymentError = async (error, orderId) => {
    console.error("Payment error:", error);
    try {
      const errorResponse = await axios.post(
        `${BASE_COMMON_URL}/api/v5/payment/verify-signature`,
        {
          error: error.message || "Payment failed",
          order_id: orderId
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'accesstoken': access_Token
          }
        }
      );
      console.log("Error reported:", errorResponse.data);
      alert("Payment failed. Please try again.");
    } catch (reportError) {
      console.error("Error reporting payment failure:", reportError);
    }
  };
  
  
  return (
    <>
      {subscription && (
        <>
          <div className="bg-exercise-bg px-6 py-5">
            <div className="flex items-start justify-between">
              <div className="flex-1"></div>
              <div className="flex-1 flex justify-center">
                <img src={premiumcrown} alt="Premium Crown" />
              </div>
              <div className="flex-1 flex justify-end">
                <img src={close} alt="Close" onClick={() => navigate(-1)} />
              </div>
            </div>

            <div className="text-center mt-3">
              <p className="font-bold text-2xl">{subscription?.family_name}</p>
              <p className="mt-2 text-xs font-normal text-[#555555]">
                {subscription?.description}
              </p>
            </div>
            <div className="flex justify-center mt-5">
              <img
                src={`${process.env.REACT_APP_S3_BUCKET_URL}/${subscription?.plan_benefit_image}`}
              />
            </div>
          </div>
          <div className="bg-[] px-6 py-5">
            <div>
              <p className="font-semibold text-base">Select a plan</p>
            </div>
            {subscription.product_family_plans_onetime.map((plan, index) => (
              <div key={plan.id} className="mt-2 flex items-start p-4 border rounded-lg border-border-premium">
                <input
                  type="radio"
                  id={`plan-${index}`}
                  checked={selectedPlan?.id === plan.id}
                  onChange={() => setSelectedPlan(plan)}
                  className="w-4 h-4 text-custom-red border-gray-300 focus:ring-custom-red accent-[#8800C8]"
                />
                <label htmlFor={`plan-${index}`} className="ml-3 flex flex-col">
                  <span className="font-semibold text-base text-[#000000]">
                    {plan.plan_name}
                  </span>
                  <span className="font-normal text-sm text-[#000000]">
                    Pay ₹{plan.monthly_pricing}
                    <span className="ml-1 line-through text-xs font-normal text-[#E04949]">
                      ₹{plan.display_pricing}
                    </span>
                  </span>
                </label>
                {plan.discount > 0 && (
                  <span className="ml-auto text-[10px] font-normal text-white bg-[#89BE5F] px-2 py-1 rounded">
                    Save {plan.discount}%
                  </span>
                )}
              </div>
            ))}
          </div>
          {/* ... Bill Summary section ... */}
          <div className="bg-[] px-6 py-5" ref={priceSectionRef}>
            <div>
              <p className="font-semibold text-base">Bill Summary</p>
            </div>
            <div className="py-4">
              <div className="flex justify-between">
                <p className="text-xs font-medium">Plan Total</p>
                <p className="font-medium text-sm">
                  ₹
                  {selectedPlan.display_pricing
                    ? selectedPlan.display_pricing
                    : ""}
                </p>
              </div>
              <div className="flex justify-between mt-1">
                <p className="text-xs font-medium text-[#3B8500]">Discount</p>
                <p className="font-medium text-sm text-[#3B8500]">
                  - ₹
                  {selectedPlan?.discountedpricing}
                </p>
              </div>
              <div className="my-5 border-b-[2px] border-dashed border-gray-300"></div>
              <div className="flex justify-between">
                <p className="text-xs font-medium">Amount to be paid</p>
                <p className="font-bold text-sm">
                  ₹
                  {selectedPlan.final_pricing ? selectedPlan.final_pricing : ""}
                </p>
              </div>
            </div>
          </div>
          <BreathingExerciseFAQ />
          <TermsandprivacySection />
          <footer
            id="footer"
            className="bg-white w-full z-20 bottom-0 start-0 border-t-2 rounded-t-3xl shadow-2xl fixed"
          >
            <div className="max-w-screen-xl flex items-center justify-start gap-2 mx-auto p-4">
              <div className="w-[50%]">
                <p className="text-lg font-semibold ">
                ₹{selectedPlan ? selectedPlan.final_pricing : subscription.product_family_plans_onetime[0].final_pricing}
                </p>
                <p
                  className="text-sm font-medium text-[#8D42D0]"
                  onClick={scrollToPriceSection}
                >
                  View details
                </p>
              </div>

              <button
                type="submit"
                onClick={handlePayment}
                className="text-white w-[50%] font-bold px-2 py-4 bg-[#8D42D0] rounded-full"
              >
                Procced
              </button>
            </div>
          </footer>
        </>
      )}
    </>
  );
};

export default SubscriptionPage;
