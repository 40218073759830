import React,{useState,useEffect} from 'react'
import { useMediaQuery } from "react-responsive";
import { useLocation } from 'react-router-dom';
import Loader from '../exercise/Loader';
import {
  sendEventsToAnalyticsSystems
} from "../../utils/analytics";
import SeoHelmet from "../../utils/SeoHelmet";
const SwasaIframeScreen = () => {
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const [isLoading, setIsLoading] = useState(true);
    const { state } = useLocation();

    useEffect(() => {
      const language = localStorage.getItem('languageSelection');
      const profileData = JSON.parse(localStorage.getItem('profileDetails'));
      sendEventsToAnalyticsSystems("swaasa_instruction_screen", {
        channel:'web',
        language:language,
        mobile: localStorage.getItem('mobileNumber'),
        name:profileData?.full_name,
        age: profileData?.age,
        gender: profileData?.gender,
        user_id: localStorage.getItem('secondary_uuid'),
        tap_type:'Next',
        utm_source  :'swaasa'
      },localStorage.getItem("secondary_uuid"));
  
    }, []);
  return (
    <>
    <SeoHelmet title='Testing screens' />
    <div className="swasa-iframe-container">
      {isLoading && (
        <Loader/>
      )}
      <iframe
        src={state?.pwa_link}
        width="100%"
        height="100%"
        allow="camera; microphone"
        allowFullScreen={true}
        onLoad={() => setIsLoading(false)}
      ></iframe>
      <style jsx>{`
        .swasa-iframe-container {
          width: 100%;
          height: 100vh;
          overflow: hidden;
        }
      `}</style>
    </div>
    </>
  )
}

export default SwasaIframeScreen