import React, { useState, useEffect,useRef } from "react";
import InstallAppHeader from "./InstallAppHeader";
import OtpInput from "react-otp-input";
import { useParams, useNavigate,useLocation } from "react-router-dom";
import axios from "axios";
import { AUTH_BASE_URL,BASE_URL,BASE_COMMON_URL } from "../../utils/ApiConstants";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import editIcon from "../../assests/icons/editicon.png";
import { showErrorToast,showSuccessToast } from "../../utils/Toast";
import { sendEventsToAnalyticsSystems } from "../../utils/analytics";
import loginbanner from '../../assests/images/Profiledetail.png';
const OTPVerify = ({ phone_number, setVerifyOtp, token, isnew,isAgreed,iswhatsappAgreed,urlRedirect,urlRedirectFrom }) => {
  const [otp, setOtp] = useState("");
  const [isVerifyEnabled, setIsVerifyEnabled] = useState(false);
  const [countdown, setCountdown] = useState(30);
  const [resendAttempts, setResendAttempts] = useState(0);
  const [isPremium, setIsPremium] = useState(false);
  const [error, setError] = useState("");
  const otpInputRefs = useRef([]);
  const navigate = useNavigate();
  useEffect(() => {
    // Enable the verify button when OTP length is 4
    setIsVerifyEnabled(otp.length === 4);
    if (otp.length === 4) {
      closeKeyboard();
      handleVerify();
    }
  }, [otp]);
  // const handleVerify = () => {};
  useEffect(() => {
    if (otpInputRefs.current[0]) {
      otpInputRefs.current[0].focus();
    }
  }, []);
  const closeKeyboard = () => {
    // Blur all input fields to close the keyboard
    otpInputRefs.current.forEach(input => {
      if (input) input.blur();
    });
  };
  const checkPremiumStatus = async (accessToken) => {
    try {
      const response = await axios.get(`${BASE_URL}/breathing/exercise?lang=en`, {
        headers: {
          accesstoken: accessToken,
          platform: "android",
          appVersion: "5.2"
        }
      });

      if (response.data.response.code === 200) {
        const mainHeaderObject = response.data.data.find(item => item.title === "mainHeader");
        if (mainHeaderObject) {
          const userIsPremium = mainHeaderObject.data.user_is_premium;
          setIsPremium(userIsPremium);
          localStorage.setItem("userIsPremium", userIsPremium.toString());
        }
      }
    } catch (error) {
      console.error("Error checking premium status:", error);
    }
  };
  useEffect(() => {
    let timer;
    if (countdown > 0) {
      timer = setTimeout(() => setCountdown(countdown - 1), 1000);
    }
    return () => clearTimeout(timer);
  }, [countdown]);
  // const handleVerify = async () => {
  //   try {
  //     const response = await axios.post(
  //       `${AUTH_BASE_URL}/api/v1/user/otp_verify`,
  //       {
  //         is_new: isnew,
  //         phone_code: "+91",
  //         phone_number: phone_number,
  //         otp: otp,
  //       },
  //       {
  //         headers: {
  //           timestamp: Date.now().toString(),
  //           accesstoken: token,
  //         },
  //       }
  //     );

  //     console.log("OTP verification response:", response.data);
  //     if (
  //       response?.data?.response?.code === 200 &&
  //       response?.data?.response?.status === "success"
  //     ) {
  //       if (
  //         response?.data?.data?.access_token &&
  //         response.data.data?.refresh_token
  //       ) {
  //         // Store the tokens in localStorage
  //         localStorage.setItem(
  //           "accesstoken",
  //           response?.data?.data?.access_token
  //         );
  //         localStorage.setItem(
  //           "refreshtoken",
  //           response?.data?.data?.refresh_token
  //         );
  //         localStorage.setItem(
  //           "secondary_uuid",
  //           response?.data?.data?.secondary_uuid
  //         );
  //         localStorage.setItem("completedRegularExerciseCount", 0);
  //         localStorage.setItem("mobileNumber", phone_number);
  //         console.log("Tokens stored in localStorage");
  //         try {
  //           const onboardingResponse = await axios.post(
  //             `${AUTH_BASE_URL}/api/v1/onboarding/webview`,
  //             {
  //               whatsapp_consent: iswhatsappAgreed,
  //               web_consent: true,
  //               terms_conditions: isAgreed
  //             },
  //             {
  //               headers: {
  //                 accesstoken: token,
  //                 timestamp: Date.now().toString(),
  //               }
  //             }
  //           );
  //           console.log("Onboarding response:", onboardingResponse.data);
  //           // Handle the onboarding response as needed
  //         } catch (onboardingError) {
  //           console.error("Onboarding API error:", onboardingError);
  //           // Handle onboarding error
  //         }
          
  //       } else {
  //         console.log("Tokens not found in the response");
  //       }
  //       // Handle successful verification here
  //       // For example, navigate to the next page or show a success message
  //       navigate("/");
  //     } else {
  //       console.log("error");
  //     }
  //     // Check if the response contains the tokens
  //   } catch (error) {
  //     console.error("OTP verification error:", error);
  //     setError(error.response?.data?.response?.alert?.[0].message);

  //     // Handle verification error here
  //     // For example, show an error message to the user
  //   }
  // };
  useEffect(() => {
    if(urlRedirect === "swaasa"){
      sendEventsToAnalyticsSystems("swaasa_verify_otp",{
        channel:'web',
        phone_code:"+91",
        language:localStorage.getItem('languageSelection'),
        mobile:phone_number,
        tapType:"login",
      });
    }else if (urlRedirect === "careplix"){
      sendEventsToAnalyticsSystems("careplix_otp_screen",{
        channel:'web',
        phone_code:"+91",
        screen_name:'OTP',
        language:localStorage.getItem('languageSelectionCarePlix'),
        mobile:phone_number,
        tapType:"login",
      });
    }else{
      sendEventsToAnalyticsSystems("on_otpverify_screen",{
        phone_code:"+91",
        userPhoneNumber:phone_number,
        tapType:"login",
        utm_source  :'breathing_exercise_web'
      });
    }
    
   }, [])
  // const handleVerify = async () => {
  //   try {
  //     const response = await axios.post(
  //       `${AUTH_BASE_URL}/api/v1/user/otp_verify`,
  //       {
  //         is_new: isnew,
  //         phone_code: "+91",
  //         phone_number: phone_number,
  //         otp: otp,
  //       },
  //       {
  //         headers: {
  //           timestamp: Date.now().toString(),
  //           accesstoken: token,
  //         },
  //       }
  //     );
  
  //     console.log("OTP verification response:", response.data);
  //     if (
  //       (response?.data?.response?.code === 200 || response?.data?.response?.code === 201) &&
  //       response?.data?.response?.status === "success"
  //     ) {
  //       if (
  //         response?.data?.data?.access_token &&
  //         response.data.data?.refresh_token
  //       ) {
  //         // Store the tokens in localStorage
  //         localStorage.setItem(
  //           "accesstoken",
  //           response?.data?.data?.access_token
  //         );
  //         localStorage.setItem(
  //           "refreshtoken",
  //           response?.data?.data?.refresh_token
  //         );
  //         localStorage.setItem(
  //           "secondary_uuid",
  //           response?.data?.data?.secondary_uuid
  //         );
  //         localStorage.setItem("completedRegularExerciseCount", 0);
  //         localStorage.setItem("mobileNumber", phone_number);
  //         sendEventsToAnalyticsSystems("on_otpverify_tap", {
  //           userPhoneNumber: phone_number,
  //           // exerciseNumber: index + 1,
  //           verify_otp: 'yes',
  //           user_unique_id:response?.data?.data?.secondary_uuid
  //         });
  //         // Call the onboarding API
  //         try {
  //           const onboardingResponse = await axios.post(
  //             `${AUTH_BASE_URL}/api/v1/onboarding/webview`,
  //             {
  //               whatsapp_consent: iswhatsappAgreed,
  //               web_consent: true,
  //               terms_conditions: isAgreed
  //             },
  //             {
  //               headers: {
  //                 accesstoken: response?.data?.data?.access_token,
  //                 timestamp: Date.now().toString(),
  //               }
  //             }
  //           );
  //           await checkPremiumStatus(response?.data?.data?.access_token);

  //           if (urlRedirect === "swaasa") {
  //             try {
  //               const userProfileResponse = await axios.get(
  //                 'https://ciplauser-authn-apis.cdhl.in/api/v2/get_user_profile',
  //                 {
  //                   headers: {
  //                     'Accept': 'application/json, text/plain, */*',
  //                     'appVersion': '6.0',
  //                     'deviceBrand': 'realme',
  //                     'deviceModel': 'RMX3371',
  //                     'device_name': 'realme',
  //                     'deviceVersion': '14',
  //                     'platform': 'android',
  //                     'timestamp': Date.now().toString(),
  //                     'accesstoken': accessToken,
  //                     'Access-Control-Allow-Origin': '*'
  //                   }
  //                 }
  //               );
                
  //               const userData = userProfileResponse.data;
                
  //               if (userData.full_name && userData.gender && userData.user_age) {
  //                 // Store user data in localStorage
  //                 localStorage.setItem('userFirstName', userData.full_name);
  //                 localStorage.setItem('userGender', userData.gender);
  //                 localStorage.setItem('userAge', userData.user_age);
                  
  //                 // Navigate to assessment screen
  //                 navigate("/assessment");
  //               } else {
  //                 // Navigate to profile screen if any data is missing
  //                 navigate("/profile");
  //               }
  //             } catch (userProfileError) {
  //               console.error("Error fetching user profile:", userProfileError);
  //               // Navigate to profile screen if there's an error
  //               navigate("/profile");
  //             }
  //           }else{

  //           }
  //           // Handle the onboarding response as needed
  //         } catch (onboardingError) {
  //           console.error("Onboarding API error:", onboardingError);
  //           // Handle onboarding error
  //         }
          
  //         // Navigate to the home page
  //         // navigate("/");
  //         const fromPremiumExercise = localStorage.getItem('fromPremiumExercise');
  //         const premiumUser = localStorage.getItem('userIsPremium');
  //         if (fromPremiumExercise === 'true' && premiumUser !=="true") {
  //           // Clear the localStorage items
  //           localStorage.removeItem('fromPremiumExercise');
  //           // Navigate to subscription page
  //           navigate('/subscription');
  //         } else {
  //           navigate("/");
  //         }
  //       } else {
  //         console.log("Tokens not found in the response");
  //       }
  //     } else {
  //       console.log("error");
  //     }
  //   } catch (error) {
  //     console.error("OTP verification error:", error);
  //     setError(error.response?.data?.response?.alert?.[0].message);
  //   }
  // };
  
  
  const handleVerify = async () => {
    try {
      const response = await axios.post(
        `${AUTH_BASE_URL}/api/v1/user/otp_verify`,
        {
          is_new: isnew,
          phone_code: "+91",
          phone_number: phone_number,
          otp: otp,
        },
        {
          headers: {
            timestamp: Date.now().toString(),
            accesstoken: token,
          },
        }
      );
      
      console.log("OTP verification response:", response.data);
      if (
        (response?.data?.response?.code === 200 || response?.data?.response?.code === 201) &&
        response?.data?.response?.status === "success"
      ) {
        if (
          response?.data?.data?.access_token &&
          response.data.data?.refresh_token
        ) {
          // Store the tokens in localStorage
          const accessToken = response?.data?.data?.access_token;
          localStorage.setItem("accesstoken", accessToken);
          localStorage.setItem("refreshtoken", response.data.data?.refresh_token);
          localStorage.setItem("secondary_uuid", response?.data?.data?.secondary_uuid);
          localStorage.setItem("completedRegularExerciseCount", 0);
          localStorage.setItem("mobileNumber", phone_number);
          if(urlRedirect === "swaasa"){
            sendEventsToAnalyticsSystems("swaasa_login_success", {
              channel:'web',
              language:localStorage.getItem('languageSelection'),
              mobile: phone_number,
              verify_otp: 'yes',
              user_id: response?.data?.data?.secondary_uuid,
              utm_source  :'swaasa'
            },response?.data?.data?.secondary_uuid);
          }else if (urlRedirect === "careplix"){
            sendEventsToAnalyticsSystems("careplix_otp_screen_tap", {
              channel:'web',
              language:localStorage.getItem('languageSelectionCarePlix'),
              mobile: phone_number,
              tap_type:'verify',
              screen_name:'OTP',
              user_id: response?.data?.data?.secondary_uuid,
              utm_source  :'careplix'
            },response?.data?.data?.secondary_uuid);
          }else{
            sendEventsToAnalyticsSystems("on_otpverify_tap", {
              userPhoneNumber: phone_number,
              verify_otp: 'yes',
              user_unique_id: response?.data?.data?.secondary_uuid,
              utm_source  :'breathing_exercise_web'
            }, response?.data?.data?.secondary_uuid);
          }
         

          // Call the onboarding API
          try {
            await axios.post(
              `${AUTH_BASE_URL}/api/v1/onboarding/webview`,
              {
                whatsapp_consent: iswhatsappAgreed,
                web_consent: true,
                terms_conditions: isAgreed
              },
              {
                headers: {
                  accesstoken: accessToken,
                  timestamp: Date.now().toString(),
                }
              }
            );
            await checkPremiumStatus(accessToken);
            
            // if (urlRedirect === "swaasa" || urlRedirect === "careplix") {
            //   // Call user profile API only for swaasa redirect
            //   try {
            //     const userProfileResponse = await axios.get(
            //       `${AUTH_BASE_URL}/api/v2/get_user_profile`,
            //       {
            //         headers: {
            //           'accesstoken': accessToken,
            //         }
            //       }
            //     );
                
            //     const userData = userProfileResponse.data.data;
            //     // Check if all required user data is present
            //       const isUserDataComplete =
            //         userData.full_name && userData.gender && userData.user_age;

            //       if (isUserDataComplete) {
            //         // Store user data in localStorage
            //         const profileDetails = {
            //           full_name: userData.full_name,
            //           age: userData.user_age,
            //           gender: userData.gender,
            //           invite_code: userData.other_invite_code
            //         };
            //         localStorage.setItem("profileDetails", JSON.stringify(profileDetails));

            //         // Handle navigation based on urlRedirect
            //         if (urlRedirect === "careplix") {
            //           try {
            //             // Fetch CarePlix details
            //             const storedLang = localStorage.getItem("languageSelectionCarePlix");
            //             const baseUrl = process.env.REACT_APP_WEBSITE_URL;
            //             const redirectUrl = `${baseUrl}/lung-health-assessment/${urlRedirectFrom}`;
            //             const response = await axios.get(
            //               `${BASE_COMMON_URL}/api/v1/careplix-services/careplix-scan-details?lang=${storedLang}&platform=web&retry_url=${encodeURIComponent(redirectUrl)}`,
            //               {
            //                 headers: { accesstoken: accessToken },
            //               }
            //             );
            //             const pwa_link = response?.data?.data?.pwa_link;
            //             localStorage.setItem("careplixLogin", "true");
            //             // Navigate to CarePlix assessment
            //             navigate("/lung-health-assessment/assessment", { state: { pwa_link } });
            //           } catch (error) {
            //             console.error("Error fetching CarePlix details:", error);
            //           }
            //         } else {
            //           // Navigate to regular assessment
            //           // navigate("/lung-health-test/assessment");
            //           // localStorage.setItem("swaasaLogin", "true");
            //           try {
            //             // Fetch CarePlix details
            //             const storedLang = localStorage.getItem("languageSelection");
            //             const response = await axios.get(
            //               `${BASE_COMMON_URL}/api/v1/swaasa-services/swaasa-scan-details?lang=${storedLang}&platform=web`,
            //               {
            //                 headers: { accesstoken: accessToken },
            //               }
            //             );
            //             const pwa_link = response?.data?.data?.pwa_link;
            //             localStorage.setItem("swaasaLogin", "true");
            //             // Navigate to CarePlix assessment
            //             navigate("/lung-health-test/assessment", { state: { pwa_link } });
            //           } catch (error) {
            //             console.error("Error fetching CarePlix details:", error);
            //           }
            //         }
            //       } else {
            //         // Handle incomplete user data
            //         if (urlRedirect === "careplix") {
            //           navigate("/lung-health-assessment/profile",{state:{urlRedirectFrom:urlRedirectFrom}});
            //           localStorage.setItem("careplixLogin", "true");
            //         } else {
            //           navigate("/lung-health-test/profile",{state:{urlRedirectFrom:urlRedirectFrom}});
            //           localStorage.setItem("swaasaLogin", "true");
            //         }
            //       }
            //   } catch (userProfileError) {
            //     console.error("Error fetching user profile:", userProfileError);
            //     // Navigate to profile screen if there's an error
            //     if (urlRedirect === "careplix") {
            //       navigate("/lung-health-assessment/profile",{state:{urlRedirectFrom:urlRedirectFrom}});
            //       localStorage.setItem('careplixLogin', 'true');
            //     } else {
            //       navigate("/lung-health-test/profile",{state:{urlRedirectFrom:urlRedirectFrom}});
            //       localStorage.setItem('swaasaLogin', 'true');
            //     }
            //   }
            // } else {
            //   // For non-swaasa redirects, navigate based on premium status
            //   const fromPremiumExercise = localStorage.getItem('fromPremiumExercise');
            //   const premiumUser = localStorage.getItem('userIsPremium');
            //   if (fromPremiumExercise === 'true' && premiumUser !== "true") {
            //     localStorage.removeItem('fromPremiumExercise');
            //     navigate('/subscription');
            //   } else {
            //     navigate("/");
            //   }
            // }
            if (urlRedirect === "swaasa" || urlRedirect === "careplix") {
              if (urlRedirect === "swaasa") {
                // For Swaasa, navigate to profile page
                navigate("/lung-health-test/profile", { state: { urlRedirectFrom: urlRedirectFrom } });
                localStorage.setItem("swaasaLogin", "true");
              } else {
                // For CarePlix, navigate to profile page
                navigate("/lung-health-assessment/profile", { state: { urlRedirectFrom: urlRedirectFrom } });
                localStorage.setItem("careplixLogin", "true");
              }
            } else {
              // For non-swaasa/careplix redirects, navigate based on premium status
              const fromPremiumExercise = localStorage.getItem('fromPremiumExercise');
              const premiumUser = localStorage.getItem('userIsPremium');
              if (fromPremiumExercise === 'true' && premiumUser !== "true") {
                localStorage.removeItem('fromPremiumExercise');
                navigate('/subscription');
              } else {
                navigate("/");
              }
            }
          } catch (onboardingError) {
            console.error("Onboarding API error:", onboardingError);
          }
        } else {
          console.log("Tokens not found in the response");
        }
      } else {
        console.log("error");
        showErrorToast("OTP verification failed. Please try again.");
      }
    } catch (error) {
      console.error("OTP verification error:", error);
      setError(error.response?.data?.response?.alert?.[0].message);
      showErrorToast("OTP verification failed. Please try again.");
    }
  };
  
  const handleResendOtp = async () => {
    if (countdown > 0) return;

    try {
      const response = await axios.post(
        `${AUTH_BASE_URL}/api/v1/resend/otp`,
        {
          phone_code: "+91",
          phone_number: phone_number,
          tapType: "resend",
        },
        {
          headers: {
            timestamp: Date.now().toString(),
            accesstoken: token,
            appVersion: "5.8",
            deviceBrand: "google",
            deviceModel: "sdk_gphone64_arm64",
            device_name: "sdk_gphone64_arm64",
            deviceVersion: "14",
            platform: "android",
          },
        }
      );

      console.log("OTP resent successfully:", response.data);
      setResendAttempts(resendAttempts + 1);
      setCountdown(30 + resendAttempts * 30); // Increment by 30 seconds each time
      showSuccessToast('OTP resent successfully!');
    } catch (error) {
      console.error("Error resending OTP:", error);
      showErrorToast("Failed to resend OTP. Please try again.");
    }
  };
  const isHindiLang = (localStorage.getItem("languageSelection") || localStorage.getItem("languageSelectionCarePlix")) === "HI"
  return (
    <>
      {/* <InstallAppHeader /> */}
      {/* <Carousel /> */}
      {/* <div className="w-full z-20 bottom-0 start-0  rounded-t-5xl  fixed">
        <div className="bg-gradient-to-r from-purple-400 to-purple-600 h-[100px]  rounded-t-3xl text-white font-sm text-center p-2 absolute top-[-50px] -z-10 w-full">
          <p className="pt-1">
            Install app now and earn{" "}
            <span className="font-sm">500 breathefree coins</span>
          </p>
        </div> */}
      <div className="max-w-md mx-auto px-4 rounded-lg rounded-t-3xl bg-white pb-5 relative z-10 py-6">
        {/* <div className="p-1 mt-3">
            <h2 className="text-2xl font-bold mb-2">Login to Breathefree</h2>
            <p className="mb-4">Enter your phone number to receive OTP</p>
            <div className="mb-4">
              <label
                htmlFor="phoneNumber"
                className="block text-gray-700 font-bold mb-1"
              >
                Phone number<span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="phoneNumber"
                placeholder="Eg. 9000000000"
                className="w-full p-2 border border-gray-300 rounded-lg"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </div>
            <div className="flex">
              <div className="flex items-start mb-4">
                <input
                  type="checkbox"
                  id="whatsapp"
                  className="mr-2 custom-checkbox"
                  checked={iswhatsappAgreed}
                  onChange={(e) => setIsWhatsAppAgreed(e.target.checked)}
                />
                <label htmlFor="whatsapp" className="text-gray-700 text-sm">
                  I agree to give permission for whatsapp
                </label>
              </div>
            </div>
            <div className="flex">
              <div className="flex items-start mb-4">
                <input
                  type="checkbox"
                  id="agreement"
                  className="mr-2"
                  checked={isAgreed}
                  onChange={(e) => setIsAgreed(e.target.checked)}
                />
                <label htmlFor="agreement" className="text-gray-700 text-sm">
                  I agree to Breathefree's{" "}
                  <a href="#" className="text-green-500 underline">
                    Privacy Policy
                  </a>{" "}
                  and{" "}
                  <a href="#" className="text-green-500 underline">
                    Terms & Conditions
                  </a>
                </label>
              </div>
              <button
                className={`px-4 py-4 rounded-2xl text-white ${
                  isAgreed && phoneNumber
                    ? "bg-green-500"
                    : "bg-gray-300 cursor-not-allowed"
                }`}
                disabled={!isAgreed || !phoneNumber}
              >
                Continue
              </button>
            </div>
          </div> */}
        {/* <div className="max-w-md mx-auto px-4 rounded-lg rounded-t-3xl bg-white pb-5 relative z-10 py-6">
            <div className="p-1 mt-3">
              <h2 className="text-2xl font-semibold mb-2">Verify OTP</h2>
              <p className="mb-4 text-sm font-normal">Sent to +91 9850687986</p>

              <div className="flex">
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  numInputs={4}
                  renderInput={(props) => (
                    <input
                      {...props}
                      className=" h-[50px] mr-4 border-2 border-gray-300 rounded text-center text-5xl"
                    />
                  )}
                />
              </div>
              <div className="flex justify-between">
                <div className="flex items-center mb-4">Didn’t receive it?</div>

                <button
                className="bg-button-bg-color text-base font-semibold px-5 py-4 rounded-full text-white"
                // className={`text-base font-semibold px-5 py-4 rounded-full text-white ${
                //   isButtonEnabled
                //     ? "bg-button-bg-color"
                //     : "bg-gray-300 cursor-not-allowed"
                // }`}
                // disabled={!isButtonEnabled}
                // onClick={handleLoginLClick}
                >
                  Verify
                </button>
              </div>
            </div>
          </div> */}
        <div className="bg-white px-4 rounded-b-3xl ">
          <ToastContainer />
          {/* <h2 className="text-2xl font-bold mb-4">Verify OTP</h2> */}

          <p className="text-sm mb-4 flex">
            {/* Sent to +91 {phone_number}{" "} */}
            
          </p>
          <div className="flex items-center">
          <p className=" text-2xl font-semibold mb-2">
          {!isHindiLang ?'Enter OTP':'ओटीपी दर्ज करें'}

            <span className="text-red-500">*</span>
            </p>
            <span
              onClick={() => {
                setVerifyOtp(false);
              }}
              className="text-[#3B8500] cursor-pointer"
            >
              <div className="ml-2 flex items-center">
                <img src={editIcon} className="" />
                <p className="text-xs font-semibold ml-1"> {!isHindiLang ?`EDIT`:`संपादित करें`}</p>
              </div>
            </span>
            </div>

          <OtpInput
            value={otp}
            onChange={setOtp}
            numInputs={4}
            inputType="tel"
            renderSeparator={<span className="w-2"></span>}
            placeholder={true}
            renderInput={(props, index) => (
              <input
                {...props}
                ref={(el) => (otpInputRefs.current[index] = el)}
                placeholder="0"
                style={{
                  width: '3.5rem',
                  height: '3.5rem',
                  fontSize: '1.5rem',
                  border: '1px solid #EBEBEB',
                  borderRadius: '0.375rem',
                  textAlign:'center',
                  transition: 'background-color 0.3s, border-color 0.3s',
                }}
                className="otp-input"
                onChange={(e) => {
                  props.onChange(e);
                  if (e.target.value && index < 3) {
                    otpInputRefs.current[index + 1].focus();
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Backspace' && !e.target.value && index > 0) {
                    otpInputRefs.current[index - 1].focus();
                  }
                }}
              />
            )}
           
            focusedBorderColor='#FFBB00'
            containerStyle="justify-start"
          />
          <p className="text-red-600">{error}</p>
          <div className="fixed bottom-5 left-5 right-5 flex justify-between items-center mt-6">
            {countdown > 0 ? (
              <p>
                {!isHindiLang ?`Resend OTP in ${Math.floor(countdown / 60)}:${countdown % 60 < 10 ? '0' : ''}${countdown % 60}`:`${Math.floor(countdown / 60)}:${countdown % 60 < 10 ? '0' : ''}${countdown % 60} में ओटीपी पुनः भेजें`}
              </p>
            ) : (
              <p className="text-xs font-normal text-[#999999]">
                {!isHindiLang ?`Didn't receive it?`:'ओटीपी प्राप्त नहीं हुआ?'}
                {isHindiLang && <br/>}
                <span
                  className="text-xs text-[#3B8500] ml-1 font-semibold cursor-pointer"
                  onClick={handleResendOtp}
                >
                 {!isHindiLang ?`RESEND OTP`:`ओटीपी दोबारा भेजें`} 
                </span>
              </p>
            )}

            <button
              className={`flex justify-between items-center gap-7 text-base font-semibold px-5 py-2 rounded-full text-white ${
                isVerifyEnabled
                  ? "bg-button-bg-color"
                  : "bg-[#EBEBEB] cursor-not-allowed text-[#8d8d8d]"
              }`}
              onClick={handleVerify}
              disabled={!isVerifyEnabled}
            >
              {!isHindiLang ? `Verify`:`सत्यापित करें`}
              <span className="text-2xl">{`>`}</span>
            </button>
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
};

export default OTPVerify;
